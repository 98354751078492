/* eslint-disable react/no-array-index-key */
import React from 'react'
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

// Elements
import ParseContent from 'components/shared/ParseContent'
import BlockPrimary from 'components/elements/Blocks/BlockPrimary'
import LinkPrimary from 'components/elements/Links/LinkPrimary'
import NavLink from 'components/shared/NavLink'

// Images
import BackgroundCircle from 'img/backgroundcircle.inline.svg'

// Interface
import { ServicesProps } from 'components/flex/Services/ServicesShell'

const Section = styled.section`
  position: relative;
  padding-bottom: 7rem;
  font-family: ${({ theme }) => theme.font.family.primary};
`

const Content = styled(ParseContent)`
  line-height: ${({ theme }) => theme.font.lineheight.huge} !important;
  font-weight: ${({ theme }) => theme.font.weight.bold} !important;
  font-size: ${({ theme }) => theme.font.size.huge} !important;
`

const StyledLinkPrimary = styled(LinkPrimary)`
  font-weight: ${({ theme }) => theme.font.weight.bold};

  & svg {
    width: 23px;
    height: 23px;
  }

  @media (min-width: 992px) {
    font-size: ${({ theme }) => theme.font.size.huge};
  }
`

const Image = styled(Plaatjie)`
  width: 102px;
  @media (max-width: 575px) {
    position: absolute !important;
    bottom: 0;
    right: 0;
    opacity: 0.2;
  }

  & img {
    object-fit: contain !important;
  }
`

const BackgroundWrapper = styled.div`
  position: absolute;
  pointer-events: none;
  top: -1850px;
  left: 0;
  right: 0;
  z-index: -1;
`

const ServicesDetail: React.FC<ServicesProps> = ({ fields }) => (
  <Section>
    <BackgroundWrapper>
      <BackgroundCircle />
    </BackgroundWrapper>
    <div className="container py-lg-5">
      <Content content={fields.description} className="mb-5" />
      <div className="row">
        {fields.services?.map((service, index) => (
          <>
            <div className="col-lg-4 mb-4" key={index}>
              <NavLink to={service?.link?.url || '#'} className="h-100">
                <BlockPrimary className="h-100">
                  <div className="d-flex flex-column flex-sm-row justify-content-between mt-sm-4">
                    <div className="d-flex flex-column justify-content-start">
                      <StyledLinkPrimary
                        to={service?.link?.url || '#'}
                        // @ts-ignore
                        arrowBottom
                      >
                        <Content content={service?.link?.title} />
                      </StyledLinkPrimary>
                    </div>
                    <Image image={service?.icon} alt="" />
                  </div>
                </BlockPrimary>
              </NavLink>
            </div>
          </>
        ))}
      </div>
    </div>
  </Section>
)

export default ServicesDetail
