/* eslint-disable react/no-array-index-key */
import React from 'react'
import { motion } from 'framer-motion'

// Third party
import styled, { css } from 'styled-components'

import Plaatjie from '@ubo/plaatjie'

// Elements
import ParseContent from 'components/shared/ParseContent'
import BlockPrimary from 'components/elements/Blocks/BlockPrimary'
import LinkPrimary from 'components/elements/Links/LinkPrimary'
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'
import NavLink from 'components/shared/NavLink'

import ArrowRight from 'img/arrowright.inline.svg'

// Interface
import { ServicesProps } from 'components/flex/Services/ServicesShell'

const Section = styled.section`
  position: relative;
  font-family: ${({ theme }) => theme.font.family.primary};

  @media (min-width: 1440px) {
    margin-top: -16rem;
  }
  @media (max-width: 1439px) {
    margin-top: -9rem;
  }
`

const Content = styled(ParseContent)``

const H3 = styled.h2`
  font-weight: bold;
  @media (min-width: 992px) {
    font-size: 26px !important;
    line-height: 27px !important;
  }

  @media (max-width: 575px) {
    font-size: 17px !important;
    line-height: 18px !important;
  }
`

const StyledLinkPrimary = styled(LinkPrimary)`
  font-weight: ${({ theme }) => theme.font.weight.bold} !important;

  & svg {
    width: 23px;
    height: 23px;
  }

  @media (min-width: 992px) {
    font-size: 26px !important;
    line-height: 27px !important;

    & * {
      font-size: 26px !important;
      line-height: 27px !important;
    }

    & > a {
      font-weight: ${({ theme }) => theme.font.weight.bold} !important;
      font-size: 26px !important;
      line-height: 27px !important;

      & > span {
        font-weight: ${({ theme }) => theme.font.weight.bold} !important;
        font-size: 26px !important;
        line-height: 27px !important;

        & > h3 {
          font-weight: ${({ theme }) => theme.font.weight.bold} !important;
          font-size: 26px !important;
          line-height: 27px !important;
        }
      }
    }
  }

  @media (max-width: 350.98px) {
    font-size: 15px;
  }
`

const StyledNoLink = styled.span`
  font-weight: ${({ theme }) => theme.font.weight.bold};

  & svg {
    width: 23px !important;
    height: 23px !important;
  }

  @media (min-width: 992px) {
    font-size: 26px !important;
    line-height: 27px !important;

    & * {
      font-size: 26px !important;
      line-height: 27px !important;
    }

    & > a {
      font-weight: ${({ theme }) => theme.font.weight.bold} !important;
      font-size: 26px !important;
      line-height: 27px !important;

      & > span {
        font-weight: ${({ theme }) => theme.font.weight.bold} !important;
        font-size: 26px !important;
        line-height: 27px !important;

        & > h3 {
          font-weight: ${({ theme }) => theme.font.weight.bold} !important;
          font-size: 26px !important;
          line-height: 27px !important;
        }
      }
    }
  }

  @media (max-width: 350.98px) {
    font-size: 15px;
  }
`

const Image = styled(Plaatjie)<{ index: number }>`
  max-width: 130px;
  max-height: 130px;

  & img {
    object-fit: contain !important;
  }

  ${(props) =>
    props.index === 0 &&
    css`
      width: 302px;
      height: 195px;
    `}
  ${(props) =>
    props.index !== 0 &&
    css`
      width: 102px;
      height: 138px;
    `}
    @media (max-width: 575px) {
    position: absolute !important;
    bottom: 0;
    right: 0;
    opacity: 0.2;
  }
`

const StyledBlockPrimary = styled(BlockPrimary)`
  color: ${({ theme }) => theme.color.secondary};
`

const Services: React.FC<ServicesProps> = ({ fields }) => {
  const [hovered, setHovered] = React.useState(-1)

  return (
    <Section>
      <div className="container py-lg-5">
        <Content content={fields.description} className="mb-4" />
        <div className="row justify-content-center">
          {fields.services?.map((service, index) => {
            let colsize = '4'

            if (index < 2) {
              colsize = '6'
            }

            return (
              <>
                <div
                  className={`mb-4 h-100 col-${
                    colsize !== '4' ? '12' : '6'
                  } col-lg-${colsize}`}
                >
                  <motion.div
                    key={index}
                    onHoverStart={() => setHovered(index)}
                    onHoverEnd={() => setHovered(-1)}
                    initial={{ scale: 1 }}
                    animate={hovered === index ? { scale: 1.05 } : { scale: 1 }}
                    className="h-100"
                  >
                    {service?.link?.url !== '#' && (
                      <NavLink to={service?.link?.url || '#'} className="h-100">
                        <StyledBlockPrimary className="h-100 px-sm-5 p-4">
                          <div className="d-flex flex-column flex-sm-row justify-content-between mt-sm-4">
                            <div className="d-flex flex-column justify-content-center">
                              {/* <StyledLinkPrimary
                                to={service?.link?.url || '#'}
                                // @ts-ignore
                                arrowBottom={index > 1 || false}
                              >
                                <span className="le-special-one">
                                </span>
                              </StyledLinkPrimary> */}
                              <div className="d-flex align-items-center">
                                <H3>{service?.link?.title}</H3>
                                <ArrowRight className="ms-2" />
                              </div>

                              {index === 0 && fields.list && (
                                <ul className="ps-4">
                                  {fields.list.map(
                                    ({ listitem }: any, listIndex) => (
                                      <li key={listIndex} className="mb-1">
                                        {listitem}
                                      </li>
                                    )
                                  )}
                                </ul>
                              )}
                              {index === 1 && fields.secondlist && (
                                <ul className="ps-4">
                                  {fields.secondlist.map(
                                    ({ listitem }: any, listIndex) => (
                                      <li key={listIndex} className="mb-1">
                                        {listitem}
                                      </li>
                                    )
                                  )}
                                </ul>
                              )}
                            </div>
                            <div className="d-flex align-items-start">
                              <Image
                                image={service?.icon}
                                alt=""
                                index={index}
                              />
                            </div>
                          </div>
                        </StyledBlockPrimary>
                      </NavLink>
                    )}
                    {service?.link?.url === '#' && (
                      <BlockPrimary className="h-100 px-sm-5 p-4">
                        <div className="d-flex flex-column flex-sm-row justify-content-between mt-sm-4">
                          <div className="d-flex flex-column justify-content-center">
                            <div>
                              <StyledNoLink>
                                {service?.link?.title}
                              </StyledNoLink>
                              {service?.description && (
                                <ParseContent
                                  content={service.description}
                                  className="mt-4"
                                />
                              )}
                            </div>

                            {index === 0 && fields.list && (
                              <ul className="ps-4">
                                {fields.list.map(
                                  ({ listitem }: any, listIndex) => (
                                    <li key={listIndex} className="mb-1">
                                      {listitem}
                                    </li>
                                  )
                                )}
                              </ul>
                            )}
                            {index === 1 && fields.secondlist && (
                              <ul className="ps-4">
                                {fields.secondlist.map(
                                  ({ listitem }: any, listIndex) => (
                                    <li key={listIndex} className="mb-1">
                                      {listitem}
                                    </li>
                                  )
                                )}
                              </ul>
                            )}
                          </div>
                          <div className="d-flex align-items-start">
                            <Image image={service?.icon} alt="" index={index} />
                          </div>
                        </div>
                      </BlockPrimary>
                    )}
                  </motion.div>
                </div>
              </>
            )
          })}
          {fields.link && (
            <div className="d-flex justify-content-center mt-4">
              <ButtonPrimary to={fields.link?.url}>
                {fields.link?.title}
              </ButtonPrimary>
            </div>
          )}
        </div>
      </div>
    </Section>
  )
}

export default Services
